var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-0 ma-0 fluid"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.isSelectedBatch ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.selectedBatch.batch.name) + " | " + _vm._s(_vm.amountInMWh))])]), _c('v-card-subtitle', {
    staticClass: "ml-3 mr-3"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.createdDate) + ", ID: " + _vm._s(_vm.selectedBatch.batch.id) + " "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.batchTypeIcon))])], 1)], 1), _c('v-tabs', {
    attrs: {
      "background-color": "transparent",
      "color": "basil",
      "grow": "",
      "show-arrows": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v(_vm._s(_vm.$t("components.thg.BatchDetailCard.tab0")))]), _c('v-tab', [_vm._v(_vm._s(_vm.$t("components.thg.BatchDetailCard.tab1")))])], 1), _c('v-divider'), _c('v-card', {
    staticClass: "scrollable virtual-scroller pa-4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: "0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-status-card', {
    attrs: {
      "batch": _vm.selectedBatch
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-distribution-card', {
    attrs: {
      "batch": _vm.selectedBatch
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-partner-distribution-card', {
    attrs: {
      "loading": _vm.loading
    }
  })], 1), _c('debug', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-payout-card', {
    attrs: {
      "data": _vm.selectedBatch.thgList,
      "batch": _vm.selectedBatch
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-instruction-card', {
    attrs: {
      "batch": _vm.selectedBatch
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-batch-detail-information-change-history-card', {
    attrs: {
      "batch": _vm.selectedBatch
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    key: "1"
  }, [_c('thg-batch-detail-information-thg-table-card', {
    attrs: {
      "batch": _vm.selectedBatch,
      "data": _vm.selectedBatch.thgList
    }
  })], 1)], 1)], 1)], 1) : _c('div', {
    staticClass: "emptyDataContainer pt-10"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": require("@/assets/undraw/undraw_no_data_re_kwbl.svg"),
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.else.noData")))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "predefinedFilter": _vm.predefinedFilter,
      "showSelect": true,
      "controlElements": _vm.controlElements,
      "selectedItems": _vm.selected
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "selectActions",
      fn: function fn() {
        return [_vm.selected.length ? _c('span', [_c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(_vm.selected.length) + " " + _vm._s(_vm.$t("selected")))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "color": "error"
          },
          on: {
            "click": _vm.openDecommissionDialog
          }
        }, [_vm._v("Abmelden")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "color": "success"
          },
          on: {
            "click": _vm.openIsAutoRenewalDialog
          }
        }, [_vm._v("Abostatus verwalten")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "color": "success"
          },
          on: {
            "click": _vm.openStatusDialog
          }
        }, [_vm._v("Status ändern")])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }, {
      key: "item.decommissioningDate",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.decommissioningDateReadable) + " ")];
      }
    }])
  }), _c('thg-vehicle-decommission-dialog', {
    ref: "decommission",
    attrs: {
      "vehicles": _vm.selected
    }
  }), _c('thg-vehicle-is-auto-renewal-dialog', {
    ref: "autorenewal",
    attrs: {
      "vehicles": _vm.selected
    }
  }), _c('thg-vehicle-status-dialog', {
    ref: "status",
    attrs: {
      "vehicles": _vm.selected
    }
  }), _vm.selectedItem ? _c('thg-vehicle-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
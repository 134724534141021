var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": _vm.initialize
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingAccountgManuellSelectionDialog.btn")) + " ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog,
      "rightDisabled": !_vm.isFormValid,
      "title": _vm.$t('components.thg.ThgBillingAccountgManuellSelectionDialog.title'),
      "supressKeyboardCancel": true,
      "persistent": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.rightClick
    }
  }, [!_vm.parsed.length ? _c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingAccountgManuellSelectionDialog.description")) + " "), _c('v-text-field', {
    attrs: {
      "single-line": false,
      "rules": _vm.required,
      "outlined": "",
      "rounded": ""
    },
    model: {
      value: _vm.billingnumberUnparsed,
      callback: function callback($$v) {
        _vm.billingnumberUnparsed = $$v;
      },
      expression: "billingnumberUnparsed"
    }
  })], 1) : _vm._e(), _vm.parsed.length ? _c('div', _vm._l(_vm.parsed, function (billingNumber, idx) {
    return _c('div', {
      key: idx
    }, [_vm._v(_vm._s(idx + 1) + ": " + _vm._s(billingNumber))]);
  }), 0) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "absolute": true,
      "loading": _vm.isLoading
    },
    on: {
      "close": _vm.close
    }
  }, [_c('v-container', {
    staticClass: "pb-10"
  }, [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _vm.value ? _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.id
    }
  }), _vm._l(_vm.history, function (item, idx) {
    return _c('v-list', {
      key: idx,
      attrs: {
        "two-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.timestamp.createdReadable))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.userId))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(item.value))])], 1)], 1)], 1);
  })], 2) : _c('latest-entries-card-empty')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }